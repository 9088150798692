import React from "react";
import { Box } from "@mui/material";
import SouvenirCard from "./souvenirs/SouvenirCard";

const SouvenirsBlock = () => {
	return (
		<Box
			sx={{
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
				flexDirection: "column",
			}}
		>
			<SouvenirCard />
		</Box>
	);
};

export default SouvenirsBlock;
