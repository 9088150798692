import React from "react";
import { Fade, Grid, styled, Typography } from "@mui/material";
import OdysseeMainBlock from "../components/home/blocks/OdysseeMainBlock";
import MainTitle from "../components/home/MainTitle";
import AnchorsList from "../components/home/AnchorsList";
import FlowersBackground from "../components/home/FlowersBackground";
import MagazinesMainBlock from "../components/home/blocks/MagazinesMainBlock";
import { useGetProductsQuery } from "../store/api/product";
import MagazineSubscriptionBlock from "../components/home/blocks/MagazineSubscriptionBlock";
import SubscriptionsGiftCardBlock from "../components/home/blocks/SubscriptionsGiftCardBlock";
import SouvenirsBlock from "../components/home/blocks/SouvenirsBlock";

const sectionsArr = [
	{
		id: "souvenirs",
		label: "Idée cadeau",
		renderComponent: SouvenirsBlock,
	},
	{
		id: "odyssée",
		label: "Odyssée",
		renderComponent: OdysseeMainBlock,
	},
	{
		id: "magazines",
		label: "Magazines",
		renderComponent: MagazinesMainBlock,
		sx: {
			backgroundColor: (theme) => theme.palette.lightgrey + "33",
		},
	},
	{
		id: "abonnement-magazine",
		label: "Abonnement magazine",
		renderComponent: MagazineSubscriptionBlock,
		sx: {
			backgroundColor: (theme) => theme.palette.lightgrey + "33",
		},
	},
	{
		id: "cartes-cadeau-abonnements",
		label: "Cartes cadeaux",
		renderComponent: SubscriptionsGiftCardBlock,
	},
];

const CenteredSiteBlock = styled(Grid)(({ theme }) => {
	return {
		maxWidth: "1440px",
		width: "100%",
	};
});

const BlockTitle = styled(Typography)(({ theme }) => {
	return {
		color: theme.palette.secondary.main,
		fontSize: "31px",
		fontFamily: "Branding Bold",
	};
});

const Home = () => {
	return (
		<Grid
			container
			sx={{
				background: "white",
				width: "100%",
				overflow: "hidden",
			}}
		>
			<Grid
				item
				xs={12}
				display={"flex"}
				justifyContent={"center"}
				mt={3}
				sx={{
					position: "relative",
				}}
			>
				<FlowersBackground flowersIndex={0} />
				<FlowersBackground flowersIndex={1} />
				<FlowersBackground flowersIndex={2} />
				<FlowersBackground flowersIndex={3} />
				<FlowersBackground flowersIndex={4} />
				<FlowersBackground flowersIndex={5} />
				<MainTitle title={"Bienvenue dans notre boutique"} />
			</Grid>
			<Grid item xs={12} display={"flex"} justifyContent={"center"} mt={5}>
				<AnchorsList anchors={sectionsArr} />
			</Grid>
			{sectionsArr.map((item) => {
				if (!item.renderComponent) return null;

				const Component = item.renderComponent;
				return (
					<Grid
						item
						xs={12}
						display={"flex"}
						justifyContent={"center"}
						sx={{
							...item.sx,
							padding: (theme) => `${theme.spacing(5)} ${theme.spacing(3)}`,
						}}
					>
						<CenteredSiteBlock
							key={item.id}
							id={item.id}
							item
							display={"flex"}
							justifyContent={"center"}
							container
						>
							<Grid item xs={12} display={"flex"} alignItems={"center"} justifyContent={"flex-start"}>
								<Fade in={true} timeout={1000}>
									<BlockTitle>{item.label}</BlockTitle>
								</Fade>
							</Grid>
							<Grid
								item
								xs={12}
								mt={2}
								sx={{
									zIndex: 1,
								}}
							>
								<Component {...item} />
							</Grid>
						</CenteredSiteBlock>
					</Grid>
				);
			})}
		</Grid>
	);
};

export default Home;
