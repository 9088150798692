import React from "react";
import InexCard from "../../../InexCard";
import useIsOnScreen from "../../../../hooks/useIsOnScreen";
import { useIsMobile } from "../../../../hooks/responsive";
import { Box, Grid, styled, Typography } from "@mui/material";
import { ShoppingBagOutlined } from "@mui/icons-material";
import { CTAButton } from "../odyssee/OdysseeCard";
import imageSouvenirs from "../../../../resources/images/png/souvenirs/img.png";
import PriceFormater from "../../../PriceFormater";

const ImageContainer = styled(Box)(({ theme }) => {
	return {
		display: "flex",
		position: "relative",
		overflow: "hidden",
		width: "100%",
		height: "100%",
		minHeight: "200px",
		"& > img": {
			position: "absolute",
			width: "100%",
			height: "100%",
			objectFit: "cover",
		},
	};
});

const Title = styled(Typography)(({ theme }) => {
	return {
		fontFamily: "Branding Bold",
		fontSize: "32px",
		lineHeight: "1.2em",
	};
});

const Description = styled(Typography)(({ theme }) => {
	return {
		fontFamily: "Branding Medium",
		fontSize: "16px",
		lineHeight: "1.2em",
	};
});

const SouvenirCard = ({ ...props }) => {
	const cardRef = React.useRef(null);
	const isVisible = useIsOnScreen(cardRef);
	const isMobile = useIsMobile();

	return (
		<InexCard
			ref={cardRef}
			sx={{
				display: "flex",
				padding: 0,
				paddingTop: 0,
				paddingBottom: 0,
				boxShadow: (theme) => theme.shadows[26],
			}}
		>
			<Grid container>
				<Grid item xs={12} sm={7} md={8}>
					<ImageContainer>
						<img src="https://medias.inrees.com/img/produit/grand_15.jpg" alt={"souvenirCardImage"} />
					</ImageContainer>
				</Grid>
				<Grid
					container
					item
					xs={12}
					sm={5}
					md={4}
					spacing={4}
					pl={3}
					pr={3}
					pt={5}
					pb={5}
					style={{
						alignItems: "flex-start",
						justifyContent: "space-between",
					}}
				>
					<Grid item xs={12}>
						<Title variant="h5" component="div">
							Almanach spirituel 2025
						</Title>
					</Grid>
					<Grid item xs={12}>
						<Description>
						🌟 Créé par la rédaction d'Inexploré, cet almanach spirituel de 8 pages vous rappelle les grandes fêtes de la roue de l’année 2025, 
						les transits astrologiques majeurs et offre des explications sur les énergies des saisons & un espace pour noter vos réflexions.
						</Description>
					</Grid>
					<Grid item xs={12}>
						<PriceFormater
							price={990}
							withSign={false}
							withTaxes={false}
							withTitle={false}
							withTimeFrame={false}
						/>
					</Grid>
					<Grid item xs={12}>
						<a href={"/panier?id=15"}>
							<CTAButton
								variant={"texticon"}
								icon={
									<ShoppingBagOutlined
										sx={{
											fontSize: "26px",
											color: (theme) => theme.palette.secondary.main,
										}}
									/>
								}
								textWithGradient={false}
								disableBackground={true}
								text={"Ajouter au panier"}
								sx={{
									width: isMobile ? "100%" : undefined,
									padding: 3.5,
									marginRight: 2,
									flexDirection: "row-reverse",
									backgroundColor: (theme) => theme.palette.yellow,
								}}
							/>
						</a>
					</Grid>
				</Grid>
			</Grid>
		</InexCard>
	);
};

export default SouvenirCard;
